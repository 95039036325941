import React, { useEffect, useState, useContext, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import { UserAvatar, Icon } from "../../components/Component";
import { CommonToaster, getInitiatals, truncate } from "../../utils/Utils";
import { ChatContext } from "./ChatContext";
import classNames from "classnames";
import EmojiPicker from "emoji-picker-react";

import ChatSideBar from "./ChatSideBar";
import { MeChat, YouChat } from "./ChatPartials";
import {
  updateChattingIds,
  updateUserConvo,
} from "../../Firebase Files/firebase-service";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../Firebase Files/firebaseConfig";
import ShareMessageModal from "../components/ShareMessageModal";
import { useTheme } from "../../layout/provider/Theme";

const ChatBody = ({
  id,
  name,
  mobileView,
  setMobileView,
  setSelectedId,
  username,
  currentUser,
  selectedUser,
  createNewChat,
  setTextMsg,
  textMsg,
  user,
  chatItemClick,
  sharedPhotos,
  setLoading,
  setFilteredChatList,
  setFilterText,
  showHelloMessage,
  handleSayHello,
  setShowHelloMessage,
}) => {
  const { chatState } = useContext(ChatContext);

  const [chat, setChat] = chatState;
  const [messages, setMessages] = useState([]);
  const theme = useTheme();
  const [showToast, setShowToast] = useState(false);
  const [showToastTheme, setShowToastTheme] = useState("");
  const [showToastMessage, setShowToastMessage] = useState("");
  const [uploadingFiles, setUploadingFiles] = useState({});
  const [uploading, setUploading] = useState(false);
  const [chatRoomActive, setChatRoomActive] = useState(false);
  const [showChatRequestModal, setShowChatRequestModal] = useState(false);
  const [chatRequestMessage, setChatRequestMessage] = useState("");
  const [blockedUsersList, setBlockedUsersList] = useState([]);
  const [showDeleteChatModal, setShowDeleteChatModal] = useState(false);
  const [singleSelectedMessage, setSingleSelectedMessage] = useState("");
  const [selectedMessageDetails, setSelectedMessageDetails] = useState([]);
  const [select, setSelect] = useState(false);
  const [selectedMessageId, setSelectMessageId] = useState([]);
  const [selectedMessages, setSelectedMessages] = useState([]);

  const toggleToast = () => {
    setShowToast(!showToast);
    setShowToastMessage("");
    setShowToastTheme("");
  };

  const toggleChatRequestModal = () => {
    setShowChatRequestModal(!showChatRequestModal);
  };

  useEffect(() => {
    if (!selectedUser?.chatId || !currentUser?.id) return;

    const messagesRef = collection(
      db,
      "Chats",
      selectedUser.chatId,
      "messages"
    );
    const q = query(messagesRef, orderBy("timestamp", "asc"));

    const chatDocRef = doc(db, "Chats", selectedUser.chatId);
    const userDocId = `${currentUser.name}_${currentUser.id}`;
    const userDocRef = doc(db, "Users", userDocId);

    const unsubscribeMessages = onSnapshot(q, async (snapshot) => {
      let updatedMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Exclude messages where clearedBy includes currentUser.id
      updatedMessages = updatedMessages.filter((message) => {
        return (
          !message.clearedBy || !message.clearedBy.includes(currentUser.id)
        );
      });

      if (!chatRoomActive) {
        setChatRequestMessage(updatedMessages?.[0]);
      }
      setMessages(updatedMessages);

      if (
        updatedMessages?.length >= 1 &&
        updatedMessages?.[0]?.senderId === currentUser.id
      ) {
        toggleChatRequestModal();
      }

      // Batch update unread messages to "read: true"
      const batch = writeBatch(db);
      snapshot.docs.forEach((doc) => {
        const messageData = doc.data();
        if (messageData.readBy?.includes(currentUser?.id)) return; // Skip if already read by current user

        const messageDocRef = doc.ref;
        batch.update(messageDocRef, {
          readBy: messageData.readBy
            ? [...messageData.readBy, currentUser?.id] // Add current user to the list
            : [currentUser?.id],
        });
      });

      try {
        await batch.commit();
      } catch (error) {
        console.error("Error updating messages to read:", error);
      }
    });

    //function to check the chatRoom active status
    const unsubscribeChatStatus = onSnapshot(chatDocRef, (doc) => {
      if (doc.exists()) {
        const chatData = doc.data();
        setChatRoomActive(chatData.ChatRoomActive || false);
      } else {
        setChatRoomActive(false);
      }
    });

    //function to check the Users status
    const unsubscribeUsersStatus = onSnapshot(userDocRef, (doc) => {
      if (doc.exists()) {
        const userData = doc.data();
        setBlockedUsersList(userData.blockedIds || []);
      } else {
        console.warn("blocked ids doesnt exits");
        setBlockedUsersList([]);
      }
    });

    return () => {
      unsubscribeChatStatus();
      unsubscribeMessages();
      unsubscribeUsersStatus();
    };
  }, [selectedUser?.chatId, currentUser?.id]);

  const [Uchat, setUchat] = useState({});

  const [sidebar, setsidebar] = useState(false);
  const [inputText, setInputText] = useState("");
  const [chatOptions, setChatOptions] = useState(false);

  const emojiPickerRef = useRef(null);
  const messageRefs = useRef({});
  const textareaRef = useRef(null);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [replyingToMessageId, setReplyingToMessageId] = useState(null);
  const [incomingID, setIncomingID] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forwardMessages, setForwardMessages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [singleMessage, setSingleMessage] = useState("");
  const [shareableContact, setShareableContact] = useState([]);
  const [shareFiles, setShareFiles] = useState([]);

  // Function to toggle the modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);

    // When closing the modal, reset data
    if (isModalOpen) {
      setForwardMessages([]);
      setSingleMessage("");
      handleCancelSelect();
      setShareableContact([]);
      setShareFiles([]);
    }
    if (selectedMessages) {
      // Format the selected messages' text and time into a single string
      const messagesToCopy = selectedMessages
        .map((message) => ` ${message.text}  ${message.time}`)
        .join("\n"); // Join each message with a new line

      // Copy the formatted string to the clipboard
      navigator.clipboard
        .writeText(messagesToCopy)
        .then(() => {
          setForwardMessages(messagesToCopy);
          console.log("Messages copied to clipboard!");
        })
        .catch((err) => {
          console.error("Error copying text: ", err);
        });
    }
  };

  useEffect(() => {
    if (isModalOpen === false) {
      setForwardMessages([]);
      setSingleMessage("");
      handleCancelSelect();
    }
  }, [isModalOpen]);

  useEffect(() => {
    handleSendMessage1(textMsg);
  }, [textMsg]);

  useEffect(() => {
    setInputText("");
  }, [selectedUser]);

  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  const handleEmojiClick = (event, emojiObject) => {
    // If emojiObject is undefined, try accessing the emoji through the event
    const emoji = emojiObject?.emoji || event.emoji || event.native;

    if (emoji) {
      setInputText((prevComment) => prevComment + emoji);
    } else {
      console.error("Emoji not found in the event object", event);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      const scrollHeight = messagesEndRef.current.scrollHeight;
      const height = messagesEndRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, uploadingFiles]);

  useEffect(() => {
    chat.forEach((item) => {
      if (item.id === id) {
        setUchat(item);
      }
    });
  }, [id, chat]);

  const onChatOptions = () => {
    setChatOptions(!chatOptions);
  };

  const onInputChange = (e) => {
    setInputText(e.target.value);
  };

  const toggleMenu = () => {
    setsidebar(!sidebar);
  };

  //function to send chat request
  const sendChatRequest = async () => {
    try {
      const chatDocRef = doc(db, "Chats", selectedUser.chatId);
      await updateDoc(chatDocRef, { ChatRequestSent: true });
      console.log("chat request sent succesfully");
    } catch (error) {
      console.log("error while sending chat request:", error);
    }
  };

  const acceptChatRequest = () => {
    console.log("chat request aceepted");
  };

  const handleFileUpload = async (files) => {
    setUploading(true); // Start the upload indicator

    const filePromises = Array.from(files).map((file) => {
      const storageRef = ref(
        storage,
        `chatFiles/${currentUser.id}/${Date.now()}_${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Track upload progress for each file
            setUploadingFiles((prev) => ({
              ...prev,
              [file.name]:
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            }));
          },
          (error) => {
            console.error("File upload failed:", error);
            setUploadingFiles((prev) => {
              const updatedFiles = { ...prev };
              delete updatedFiles[file.name];
              return updatedFiles;
            });
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setUploadingFiles((prev) => {
              const updatedFiles = { ...prev };
              delete updatedFiles[file.name];
              return updatedFiles;
            });
            resolve(downloadURL);
          }
        );
      });
    });

    try {
      const fileURLs = await Promise.all(filePromises);
      setUploading(false); // Stop the upload indicator after all files are done
      return fileURLs;
    } catch (error) {
      console.error("Error uploading files:", error);
      setUploading(false);
    }
  };

  const handleSendMessage1 = async (message, files) => {
    setFilterText(null);
    updateChattingIds(`${name}_${id}`, currentUser?.id);
    updateChattingIds(
      `${currentUser?.name || currentUser?.username}_${currentUser?.id}`,
      id
    );

    if (
      (message.trim() || files?.length > 0) &&
      selectedUser?.chatId &&
      currentUser
    ) {
      const messagesRef = collection(
        db,
        "Chats",
        selectedUser.chatId,
        "messages"
      );
      let fileURLs = [];
      // Handle file uploads if any files are selected
      if (files?.length > 0) {
        fileURLs = await handleFileUpload(files);
      }
      try {
        await addDoc(messagesRef, {
          text: message || "",
          senderId: currentUser.id,
          fileURLs,
          timestamp: new Date(),
          clearedBy: [],
        });
        const q = query(messagesRef, where("senderId", "!=", currentUser.id));
        // Retrieve the messages
        const unreadMessagesSnapshot = await getDocs(q);
        const unreadMessages = unreadMessagesSnapshot.docs;
        // Update the `read` status of each message to false
        unreadMessages.forEach(async (doc) => {
          const messageRef = doc.ref; // Get reference to the message
          try {
            await updateDoc(messageRef, { read: false });
          } catch (error) {
            console.error("Error updating message read status:", error);
          }
        });
        setTextMsg(""); // Reset text input
        setChatOptions(false);
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleCancelUpload = (fileName) => {
    // Logic to cancel the file upload
    console.log(`Canceling upload for: ${fileName}`);

    // Remove the file from uploadingFiles state or cancel the upload request here
    // For example, using a state management library or directly manipulating the state.
    setUploadingFiles((prev) => {
      const { [fileName]: _, ...rest } = prev; // Remove the file
      return rest;
    });
  };

  // Handle sending a new message as reply
  const handleSendMessage = async (
    replyingMsg,
    message,
    replyingToUserId
    // replyingToUsername
  ) => {
    console.log("handleSendMessage calling");
    console.log("replyingToUserId:", replyingToUserId);
    // console.log("replyingToUsername:", replyingToUsername);

    if (message?.trim() && user?.chatId && currentUser) {
      const messagesRef = collection(db, "Chats", user.chatId, "messages");
      try {
        await addDoc(messagesRef, {
          replyingMsg: replyingMsg,
          replyingToUserId: replyingToUserId || null, // Store replying message ID if applicable
          replyingToMessageId: replyingToMessageId,
          // replyingToUsername: replyingToUsername,
          text: message,
          senderId: currentUser.id,
          timestamp: new Date(),
          reply: replyingToMessageId ? true : false,
          edited: false,
          senderName: currentUser?.name,
        });
        setInputText("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  // Handle file selection, allowing multiple files to be added to the list
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to an array
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]); // Merge with existing files
  };

  // Function to remove a selected file if needed
  const handleRemoveFile = (indexToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const onTextSubmit = async (e) => {
    if (!chatRoomActive && messages.length < 1) {
      sendChatRequest();
    }

    e.preventDefault();
    createNewChat(id);
    setChatOptions(false);

    // Ensure chat and Uchat are defined
    if (!chat || !Uchat) {
      console.error("Chat or Uchat is not defined");
      return;
    }

    // Ensure inputText is not empty or files are selected
    const files = document.getElementById("fileInput").files;
    if (inputText.trim() === "" && files.length === 0) return;

    let allChat = chat;
    let index = allChat.findIndex((item) => item.id === id);

    // Handle case where index is -1 (chat not found)
    if (index === -1) {
      console.error("Chat item not found");
      return;
    }

    let defaultChat = Uchat;
    let text = truncate(inputText, 74); // Limiting text length
    let newChatItem;

    // Send message and files
    handleSendMessage1(text, selectedFiles);

    // Clear input after sending
    setInputText(""); // Reset input text
    setSelectedFiles([]); // Clear selected files

    setUchat({ ...defaultChat });
    setInputText(""); // Reset the input field

    // Update the Firestore database
    try {
      await updateUserConvo(`${username}_${id}`, newChatItem); // Send only the new chat item
    } catch (error) {
      console.error("Error updating chat in Firestore:", error);
    }
  };

  const toggleDeleteChatModal = (id) => {
    setSingleSelectedMessage(id);
    setShowDeleteChatModal(!showDeleteChatModal);
  };

  const onRemoveMessage = () => {
    const messageRef = doc(
      db,
      "Chats",
      selectedUser.chatId,
      "messages",
      singleSelectedMessage?.id
    );
    updateDoc(messageRef, {
      deleted: true,
    });
    toggleDeleteChatModal();
  };

  const handleMsgAction = async (id, type, item) => {
    switch (type) {
      case "edit":
        const messageToEdit = messages.find((msg) => msg.id === id);
        if (messageToEdit) {
          setEditingMessageId(id);
          setInputText(messageToEdit.text);
        }
        break;
      case "copy":
        navigator.clipboard.writeText(
          messages.find((msg) => msg.id === id)?.text || ""
        );
        break;
      case "reply":
        setReplyingToMessageId(id);
        break;
      case "selectMsg":
        setSelect(true);
        handleSelectMessage(id);
        break;
      case "forwardMsg":
        toggleModal();
        if (item?.contactShare) {
          setShareableContact(item);
        }
        if (item?.fileURLs?.length > 0) {
          setShareFiles(item);
        }
        const messageToShare = messages.find((msg) => msg.id === id);
        if (messageToShare) {
          setForwardMessages(messageToShare.text);
          setSingleMessage(messageToShare.text);
        }
        break;
      default:
        break;
    }
  };

  // Handle selecting or deselecting a message
  const handleSelectMessage = (id) => {
    const selectedMessage = messages.find((message) => message.id === id);
    if (selectedMessage) {
      setSelectMessageId((prevIds = []) => {
        // Ensure prevIds is an array
        if (prevIds?.includes(id)) {
          // Deselect the message by removing it from the array
          return prevIds.filter((messageId) => messageId !== id);
        } else {
          // Select the message by adding the ID
          return [...prevIds, id];
        }
      });
    }

    if (selectedMessage) {
      setSelectedMessages((prevMessages = []) => {
        // Check if the message is already selected
        const isMessageSelected = prevMessages.some(
          (msg) => msg.messageId === id
        );

        if (isMessageSelected) {
          // Deselect the message by filtering it out
          return prevMessages.filter((msg) => msg.messageId !== id);
        } else {
          // Select the message by adding messageId and senderId
          return [
            ...prevMessages,
            {
              messageId: id,
              senderId: selectedMessage.senderId,
              text: selectedMessage.text,
              time: new Date(
                selectedMessage.timestamp.seconds * 1000
              ).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              }),
            },
          ];
        }
      });

      setSelectedMessageDetails((prevDetails = []) => {
        // Ensure prevDetails is an array
        const isMessageDetailSelected = prevDetails.some(
          (detail) =>
            detail.name === selectedMessage.senderName &&
            detail.message === selectedMessage.content
        );

        if (isMessageDetailSelected) {
          // If message already selected, remove it (deselection)
          return prevDetails.filter(
            (detail) =>
              !(
                detail.name === selectedMessage.senderName &&
                detail.message === selectedMessage.content
              )
          );
        } else {
          // Add unique message details
          return [
            ...prevDetails,
            {
              name: selectedMessage.senderName,
              message: selectedMessage.content,
            },
          ];
        }
      });
    }
  };

  // Handle editing a message
  const handleEditMessage = async () => {
    if (editingMessageId && inputText.trim()) {
      try {
        await updateDoc(
          doc(db, "Chats", user.chatId, "messages", editingMessageId),
          {
            text: inputText,
            edited: true,
          }
        );
        setEditingMessageId(null);
        setInputText("");
        setIncomingID(false);
      } catch (error) {
        console.error("Error updating message:", error);
        setIncomingID(false);
      }
    }
  };
  const repliedMessage = messages.find((msg) => msg.id === replyingToMessageId);
  // console.log("repliedMessage", repliedMessage);
  // Handle replying to a message
  const handleReply = (reply) => {
    const replyingToUserId = repliedMessage?.senderId; // Get the userId of the message you're replying to
    // const replyingToUsername = repliedMessage?.senderName; // Get the username of the message you're replying to

    handleSendMessage(
      ` ${messages.find((msg) => msg.id === replyingToMessageId)?.text} `,
      `${reply}`,
      replyingToUserId
      // replyingToUsername
    );
    setReplyingToMessageId(null);
  };
  const repliedMessageRef = useRef(null); // Ref for the replied message

  // Scroll to the replied message
  useEffect(() => {
    if (replyingToMessageId && repliedMessageRef.current) {
      repliedMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [replyingToMessageId]);
  const handleClearReply = () => {
    setReplyingToMessageId(null);
  };

  const scrollToMessage = (messageId) => {
    if (messageRefs.current[messageId]) {
      messageRefs.current[messageId].scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCancelSelect = () => {
    setSelect(false);
    setSelectMessageId([]);
    setSelectedMessages([]);
  };

  const handleCopyMessages = () => {
    // Format the selected messages' text and time into a single string
    const messagesToCopy = selectedMessages
      .map((message) => ` ${message.text}  ${message.time}`)
      .join("\n"); // Join each message with a new line

    // Copy the formatted string to the clipboard
    navigator.clipboard
      .writeText(messagesToCopy)
      .then(() => {
        handleCancelSelect();
        setShowToastMessage("Messages copied to clipboard!");
        setShowToast(!showToast);
        setShowToastTheme("green");
        // console.log("Messages copied to clipboard!");
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  const handleSelectedMessagesDelete = () => {
    // Iterate over the selected messages and mark them as deleted
    selectedMessages.forEach((message) => {
      const messageRef = doc(
        db,
        "Chats",
        selectedUser.chatId,
        "messages",
        message.messageId
      );

      // Update the 'deleted' field to true
      updateDoc(messageRef, {
        deleted: true,
      })
        .then(() => {
          handleCancelSelect();
        })
        .catch((error) => {
          console.error("Error deleting message: ", error);
        });
    });
  };

  useEffect(() => {
    // Focus the textarea when replyingToMessageId and repliedMessage are true
    if (replyingToMessageId && repliedMessage) {
      textareaRef.current.focus();
    }
    if (editingMessageId) {
      textareaRef.current.focus();
    }
  }, [replyingToMessageId, repliedMessage, editingMessageId]);

  const chatBodyClass = classNames({
    "nk-chat-body": true,
    "show-chat": mobileView,
    "profile-shown": sidebar && window.innerWidth > 1550,
  });

  const handleAcceptRequest = async () => {
    try {
      const chatDocRef = doc(db, "Chats", selectedUser.chatId);
      await updateDoc(chatDocRef, { ChatRoomActive: true });
    } catch (error) {
      console.log("error while sending chat request:", error);
    }
  };

  const handleBlock = async () => {
    try {
      const userDocId = `${currentUser.name}_${currentUser.id}`;
      const userDocRef = doc(db, "Users", userDocId);
      await updateDoc(userDocRef, {
        blockedIds: arrayUnion(Uchat.id),
      });
    } catch (error) {
      console.error("Error while blocking:", error);
    }
  };

  const handleUnblock = async () => {
    try {
      const userDocId = `${currentUser.name}_${currentUser.id}`;
      const userDocRef = doc(db, "Users", userDocId);
      await updateDoc(userDocRef, {
        blockedIds: arrayRemove(Uchat.id),
      });
    } catch (error) {
      console.error("Error while unblocking:", error);
    }
  };

  return (
    <React.Fragment>
      {Object.keys(Uchat).length > 0 && (
        <div className={chatBodyClass}>
          <div className="nk-chat-head">
            <ul className="nk-chat-head-info">
              <li
                className="nk-chat-body-close"
                onClick={() => setMobileView(false)}
              >
                <a
                  href="#hide-chat"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                  className="btn btn-icon btn-trigger nk-chat-hide ms-n1"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </li>
              <li className="nk-chat-head-user">
                <div className="user-card">
                  {Uchat?.profilePic ? (
                    <img
                      src={Uchat?.profilePic} // URL of the DJ's profile image
                      alt="Profile"
                      className="user-avatar"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }} // Ensure the image is rounded
                    />
                  ) : (
                    <UserAvatar
                      text={getInitiatals(Uchat?.name)}
                      theme="primary"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                    ></UserAvatar>
                  )}
                  <div className="user-info">
                    <div className="lead-text text-capitalize">
                      {Uchat?.name}{" "}
                      {Uchat?.id === currentUser?.id ? "(You)" : ""}
                    </div>
                    {/* <div className="sub-text">
                      {Uchat.active === true ? "Active Now" : ` `}
                    </div> */}
                  </div>
                </div>
              </li>
            </ul>
            {/* <ul className="nk-chat-head-tools">
              <li className="me-n1 me-md-n2">
                <a
                  href="#alert"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleMenu();
                  }}
                  className="btn btn-icon btn-trigger text-primary chat-profile-toggle"
                >
                  <Icon name="alert-circle-fill"></Icon>
                </a>
              </li>
            </ul> */}
          </div>

          <SimpleBar
            className="nk-chat-panel"
            scrollableNodeProps={{ ref: messagesEndRef }}
          >
            {showHelloMessage && messages?.length < 1 && (
              <div className="text-center my-4">
                <div
                  className="d-inline-block p-5"
                  style={{
                    maxWidth: "100%",
                  }}
                >
                  <p
                    className="text-muted mb-4"
                    style={{
                      fontSize: "1.25rem", // Larger font size for better readability
                      lineHeight: "1.75", // Increased line height for clarity
                      textAlign: "center", // Center the text
                    }}
                  >
                    Start a conversation with <strong>{Uchat?.name}</strong> and
                    connect seamlessly. Send a friendly greeting to begin
                    chatting!
                  </p>
                  <div className="d-flex justify-content-center gap-4">
                    <Button
                      className="p-3 btn-primary"
                      color="primary"
                      style={{
                        fontSize: "1.2rem", // Larger font for button text
                      }}
                      onClick={handleSayHello}
                    >
                      Say Hello 👋
                    </Button>
                    <Button
                      className="p-3"
                      color="secondary"
                      style={{
                        backgroundColor: "#f5f5f5", // Soft light gray
                        color: "#333", // Darker text for contrast
                        border: "1px solid #ccc", // Light border for structure
                        fontSize: "1.1rem", // Larger font for button text
                        padding: "1rem 2rem", // Increase padding for larger button
                        borderRadius: "8px", // Rounded corners
                        transition: "all 0.3s ease-in-out, transform 0.2s", // Smooth transition and hover effect
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.15)", // Subtle shadow for a raised effect
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = "#ddd"; // Lighter gray on hover
                        e.currentTarget.style.transform = "scale(1.05)"; // Slight scale-up on hover
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = "#f5f5f5"; // Revert to original color
                        e.currentTarget.style.transform = "scale(1)"; // Revert scale
                      }}
                      onClick={() => {
                        setShowHelloMessage(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {/* {blockedUsersList.includes(Uchat.id) ? (
            <div className="text-center my-3">
              <div
                className="d-inline-block p-3 rounded"
                style={{
                  backgroundColor: "#e9ecef", // Light gray background
                  border: "1px solid #dee2e6", // Subtle border
                }}
              >
                <p className="text-muted mb-2">
                  You have blocked <strong>{Uchat?.name}</strong>.
                </p>
                <div className="d-flex justify-content-center gap-3">
                  <Button
                    color="primary"
                    className="p-2"
                    type="button"
                    style={{
                      background:
                        "linear-gradient(23deg, rgb(33 0 0) 0%, rgb(153 0 0) 35%, rgb(255 69 69) 100%)",
                      border: "none",
                    }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.background =
                        "linear-gradient(23deg, rgb(255 82 82) 0%, rgb(204 0 0) 35%, rgb(102 0 0) 100%)")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.background =
                        "linear-gradient(23deg, rgb(33 0 0) 0%, rgb(153 0 0) 35%, rgb(255 69 69) 100%)")
                    }
                    onClick={() => handleUnblock()}
                  >
                    Unblock
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            !chatRoomActive &&
            messages.length >= 1 && (
              <>
                {chatRequestMessage?.senderId === currentUser.id ? (
                  <div className="text-center my-3">
                    <div
                      className="d-inline-block p-3 rounded"
                      style={{
                        backgroundColor: "#e9ecef", // Subtle light gray
                        border: "1px solid #dee2e6", // Subtle border
                      }}
                    >
                      <p className="text-muted mb-0">
                        Waiting for {Uchat?.name} to accept your invitation.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="text-center my-3">
                    <div
                      className="d-inline-block p-3 rounded"
                      style={{
                        backgroundColor: "#e9ecef", // Slightly darker gray
                        border: "1px solid #dee2e6", // Subtle border
                      }}
                    >
                      <p className="text-muted mb-2">
                        {Uchat?.name} wants to connect with you.
                      </p>
                      <div className="d-flex justify-content-center gap-3">
                        <Button
                          color="primary"
                          className="p-2"
                          type="button"
                          style={{
                            background:
                              "linear-gradient(23deg, rgb(33 0 0) 0%, rgb(153 0 0) 35%, rgb(255 69 69) 100%)",
                            border: "none",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.background =
                              "linear-gradient(23deg, rgb(255 82 82) 0%, rgb(204 0 0) 35%, rgb(102 0 0) 100%)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.background =
                              "linear-gradient(23deg, rgb(33 0 0) 0%, rgb(153 0 0) 35%, rgb(255 69 69) 100%)")
                          }
                          onClick={() => handleBlock()}
                        >
                          Block
                        </Button>
                        <Button
                          color="primary"
                          className="p-2"
                          type="button"
                          style={{
                            background:
                              "linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%)",
                            border: "none",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.background =
                              "linear-gradient(23deg, rgb(82 224 93) 0%, rgb(54 186 0) 35%, rgb(4 28 4) 100%)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.background =
                              "linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%)")
                          }
                          onClick={() => handleAcceptRequest()}
                        >
                          Accept
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )
          )} */}

            {messages?.map((item, idx) => {
              if (item?.senderId === currentUser?.id) {
                return (
                  <MeChat
                    key={idx}
                    item={item}
                    chat={Uchat}
                    toggleDeleteChatModal={toggleDeleteChatModal}
                    handleMsgAction={handleMsgAction}
                    messageRefs={messageRefs}
                    scrollToMessage={scrollToMessage}
                    select={select}
                    handleSelectMessage={handleSelectMessage}
                    selectedMessageId={selectedMessageId}
                    chatItemClick={chatItemClick}
                    uploading={uploading}
                    uploadingFiles={uploadingFiles}
                    lastMessageId={messages[messages?.length - 1]?.id}
                    handleCancelUpload={handleCancelUpload}
                  ></MeChat>
                );
              } else {
                return (
                  <YouChat
                    key={idx}
                    item={item}
                    chat={Uchat}
                    handleMsgAction={handleMsgAction}
                    messageRefs={messageRefs}
                    scrollToMessage={scrollToMessage}
                    select={select}
                    handleSelectMessage={handleSelectMessage}
                    selectedMessageId={selectedMessageId}
                    chatItemClick={chatItemClick}
                  ></YouChat>
                );
              }
            })}
          </SimpleBar>

          {selectedFiles.length > 0 && (
            <ul
              style={{
                display: "flex",
                gap: "10px",
                overflowX: "auto",
                padding: "10px",
                listStyleType: "none",
                height: 230,
                maxHeight: 230,
              }}
            >
              {selectedFiles.length > 0 &&
                selectedFiles.map((file, index) => {
                  const fileType = file.type.split("/")[0]; // Get the file type (e.g., "image", "video", etc.)

                  return (
                    <li
                      key={index}
                      style={{
                        position: "relative",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        padding: "5px",
                        minWidth: "100px",
                        minHeight: "100px",
                        overflow: "hidden",
                      }}
                    >
                      {/* Image Preview */}
                      {fileType === "image" && (
                        <div>
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                          />
                        </div>
                      )}

                      {/* Video Preview */}
                      {fileType === "video" && (
                        <div>
                          <video
                            width="100"
                            height="100"
                            controls
                            src={URL.createObjectURL(file)}
                            style={{ borderRadius: "5px" }}
                          >
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )}

                      {/* Non-media File (e.g., PDF, docs) */}
                      {fileType !== "image" && fileType !== "video" && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100px",
                            width: "100px",
                          }}
                        >
                          <Icon name="file" />{" "}
                          {/* Use any icon for non-media files */}
                          <span style={{ marginLeft: "5px" }}>{file.name}</span>
                        </div>
                      )}

                      {/* Cancel (Remove) Button */}
                      <button
                        onClick={() => handleRemoveFile(index)}
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          backgroundColor: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        &times;
                      </button>
                    </li>
                  );
                })}
            </ul>
          )}

          <div className="nk-chat-editor p-0">
            <div className="nk-chat-editor-upload  ms-n1">
              {/* <Button
                size="sm"
                className={`btn-icon btn-trigger text-primary toggle-opt ${chatOptions ? "active" : ""}`}
                onClick={() => onChatOptions()}
              >
                <Icon name="plus-circle-fill"></Icon>
              </Button> */}
              <div
                className={`${
                  theme.skin === "dark"
                    ? "chat-upload-option-dark chat-upload-option"
                    : "chat-upload-option"
                } ${chatOptions ? "expanded" : ""}`}
              >
                <ul className="">
                  <li>
                    <a
                      href="#img"
                      onClick={(ev) => {
                        ev.preventDefault();
                        document.getElementById("fileInput").click(); // Trigger file input click
                      }}
                    >
                      <Icon name="folder" />
                    </a>
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      multiple
                      onChange={handleFileChange} // Call file change handler
                    />
                  </li>
                  {/* <li>
                    <a
                      href="#camera"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon name="camera-fill"></Icon>
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      href="#mic"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon name="mic"></Icon>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#grid"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon name="grid-sq"></Icon>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="nk-chat-editor-form">
              {/* <div className="form-control-wrap"> */}
              {replyingToMessageId && repliedMessage && (
                <div className="replied-message" ref={repliedMessageRef}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h6
                      className="replied-message-title"
                      style={{ fontSize: "0.8rem", gap: 10 }}
                    >
                      <Icon name="reply-fill" className="mr-2" />
                      <span>Replying to:</span>
                    </h6>
                    <Button
                      className="clear-icon"
                      onClick={handleClearReply}
                      size="sm"
                      close
                    >
                      <Icon name="cross" className="mr-2" />
                    </Button>
                  </div>
                  <p
                    className="replied-message-text"
                    onClick={() => scrollToMessage(replyingToMessageId)}
                    style={{ cursor: "pointer" }} // Add some styles to indicate it's clickable
                  >
                    {/* {repliedMessage.text} */}
                    {repliedMessage.text
                      ?.slice(0, 150)
                      .split(" ")
                      .map((word, i) =>
                        word.match(/(https?:\/\/[^\s]+)/g) ? (
                          <span
                            key={i}
                            href={word}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {word}
                          </span>
                        ) : (
                          <span key={i}>{word}</span>
                        )
                      )
                      .reduce((prev, curr) => [prev, " ", curr])}{" "}
                    {/* Add spaces back */}
                    {repliedMessage?.text?.length > 150 ? " ..." : ""}
                  </p>
                </div>
              )}
              {select ? (
                <div className="replied-message">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <h6
                        className="replied-message-title"
                        style={{ fontSize: "0.8rem", gap: 10 }}
                      >
                        <span>
                          {selectedMessages?.length} messages selected
                        </span>
                      </h6>
                      <p style={{ fontSize: 12, marginTop: -5 }}>
                        {process.env.REACT_APP_NAME} chat
                      </p>
                    </div>
                    <Button
                      className="clear-icon"
                      onClick={handleCancelSelect}
                      size="sm"
                      close
                    >
                      <Icon name="cross" className="mr-2" />
                    </Button>
                  </div>

                  {/* Add Copy Button */}
                  <Button
                    className="copy-icon"
                    size="lg"
                    onClick={handleCopyMessages}
                  >
                    <Icon name="copy" style={{ height: 20, width: 20 }} />
                  </Button>

                  <Button
                    className="trash-icon"
                    size="lg"
                    close
                    disabled={selectedMessages.some(
                      (message) => message?.senderId === id
                    )}
                    onClick={handleSelectedMessagesDelete}
                  >
                    <Icon name="trash" style={{ height: 20, width: 20 }} />
                  </Button>
                  <Button
                    className="trash-icon"
                    size="lg"
                    close
                    onClick={toggleModal}
                  >
                    <Icon name="send" style={{ height: 20, width: 20 }} />
                  </Button>
                </div>
              ) : (
                <textarea
                  ref={textareaRef}
                  // disabled={
                  //   (chatRequestMessage !== undefined &&
                  //     chatRequestMessage?.senderId === currentUser.id &&
                  //     !chatRoomActive &&
                  //     !editingMessageId &&
                  //     messages.length >= 5) ||
                  //   (chatRequestMessage !== undefined &&
                  //     chatRequestMessage?.senderId !== currentUser.id &&
                  //     !chatRoomActive) ||
                  //   blockedUsersList.includes(Uchat?.id)
                  // }
                  className=" form-control-simple form-control no-resize px-3"
                  rows="2"
                  id="default-textarea"
                  onChange={(e) => onInputChange(e)}
                  value={inputText}
                  // placeholder={
                  //   blockedUsersList?.includes(Uchat?.id)
                  //     ? `You have blocked ${Uchat?.name}. Unblock to continue chatting.`
                  //     : !editingMessageId && !chatRoomActive
                  //     ? chatRequestMessage?.senderId === currentUser?.id &&
                  //       messages?.length >= 5
                  //       ? `You need to wait for ${Uchat?.name}'s reply to continue chatting.`
                  //       : messages?.length >= 1 &&
                  //         chatRequestMessage?.senderId !== currentUser?.id
                  //       ? `${Uchat?.name} wants to connect with you. Please accept the invitation to continue chatting.`
                  //       : "Type your message..."
                  //     : "Type your message..."
                  // }

                  placeholder="Type your message"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      if (editingMessageId) {
                        handleEditMessage(inputText);
                      } else if (replyingToMessageId) {
                        handleReply(inputText);
                      } else {
                        onTextSubmit(e);
                      }
                    }
                  }}
                ></textarea>
              )}
            </div>
            {!select && (
              // (chatRoomActive || messages.length < 5 || editingMessageId) && (
              <ul className="nk-chat-editor-tools g-2 p-4">
                <li>
                  <Button
                    color="light"
                    onClick={toggleEmojiPicker}
                    className="btn-round btn-icon bg-white text-secondary border-0"
                  >
                    <Icon name="happyf-fill" className="text-muted" />
                  </Button>
                </li>

                {showEmojiPicker && (
                  <div
                    ref={emojiPickerRef}
                    style={{
                      position: "absolute",
                      bottom: "60px", // Adjust as needed
                      right: "0",
                      zIndex: 1,
                    }}
                  >
                    <EmojiPicker onEmojiClick={handleEmojiClick} />
                  </div>
                )}
                <li>
                  <Button
                    color="primary"
                    onClick={(e) => onTextSubmit(e)}
                    className="btn-round btn-icon"
                  >
                    <Icon name="send-alt"></Icon>
                  </Button>
                </li>
              </ul>
            )}
          </div>

          <ChatSideBar
            id={id}
            sidebar={sidebar}
            chat={Uchat}
            currentUser={currentUser}
            setSelectedId={setSelectedId}
            sharedPhotos={sharedPhotos}
            setLoading={setLoading}
            setFilteredChatList={setFilteredChatList}
            isBlocked={blockedUsersList.includes(Uchat.id)}
            handleBlock={handleBlock}
            handleUnblock={handleUnblock}
          />

          {window.innerWidth < 1550 && sidebar && (
            <div
              onClick={() => toggleMenu()}
              className="nk-chat-profile-overlay"
            />
          )}
        </div>
      )}
      {/* Render the CommonToaster */}
      <CommonToaster
        showToast={showToast}
        message={showToastMessage} // Pass the dynamic message to the toast
        toggleToast={toggleToast}
        theme={showToastTheme}
      />

      {/* ShareContactModal Component */}
      <ShareMessageModal
        isOpen={isModalOpen} // Pass the state to control modal visibility
        toggle={toggleModal} // Pass the toggle function to close the modal
        forwardMessages={forwardMessages || singleMessage} // Pass the current user prop if needed
        currentUser={currentUser}
        shareableContact={shareableContact}
        shareFiles={shareFiles}
      />

      {/* modal to handle delete single chat message  */}
      <Modal
        isOpen={showDeleteChatModal}
        toggle={toggleDeleteChatModal}
        centered
      >
        <ModalHeader
          toggle={toggleDeleteChatModal}
          style={{ backgroundColor: "#1a1f36" }}
        >
          <span style={{ color: "#D3D3D3" }}>Delete message</span>
        </ModalHeader>
        <ModalBody className="text-center">
          <h5>Are you sure you want to delete this message?</h5>
          <p className="text-muted">You won't be able to revert this</p>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            color="primary"
            onClick={onRemoveMessage}
            style={{ backgroundColor: "#8395a7", borderColor: "#8395a7" }}
          >
            Yes Proceed
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setSingleSelectedMessage(null);
              toggleDeleteChatModal();
            }}
            style={{ backgroundColor: "#8395a7", borderColor: "#8395a7" }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ChatBody;
