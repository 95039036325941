import React, { useEffect, useState } from "react";
import { Button, CardImg, CardTitle } from "reactstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/Utils";
import { getUpcomingEventsList } from "../../../http/getApi";
import { useQuery } from "react-query";
import { ReactDataTable } from "../../../components/Component";
import { ShimmerTable, ShimmerThumbnail } from "react-shimmer-effects";
import { Categories } from "emoji-picker-react";

export const NotAvailable = () => (
  <div style={{ textAlign: "center" }}>----</div>
);
const PendingEvent = () => {
  const navigate = useNavigate();
  const [recordLimit, setRecordLimit] = useState(5);
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [loading, setLoading] = useState(true);

  const {
    data: upcomingEventsList,
    isLoading: upcomingEventsLoading,
    isError: upcomingEventsListError,
  } = useQuery({
    queryKey: ["get-upcoming-events-list", recordLimit, pageNo, search],
    queryFn: () =>
      getUpcomingEventsList({
        id: "",
        pageNo,
        recordLimit,
        search: search,
        booking_request_status: "0",
        category: "upcoming",
      }),
    onSuccess: () => setLoading(false),
  });

  console.log("upcomingEventsList", upcomingEventsList);

  useEffect(() => {
    if (upcomingEventsList?.totalRecords) {
      setTotalRecords(upcomingEventsList.totalRecords);
    }
  }, [upcomingEventsList, setTotalRecords]);

  const dataTableColumns = [
    {
      name: "Sr. No.",
      selector: (row, index) => pageNo * recordLimit + index + 1,
    },
    {
      name: "Event Name",
      selector: (row) => row.event_name,
    },
    {
      name: "Event Type",
      selector: (row) => row.event_type_name,
    },
    {
      name: "Event Date",
      selector: (row) => row.start_date_time,
      cell: (row) => <span>{formatDate(row.start_date_time)}</span>,
    },
    {
      name: "Venue Name",
      selector: (row) =>
        row.venue_details[0]?.address
          ? row.venue_details[0]?.address
          : "-------",
    },
  ];

  return (
    <React.Fragment>
      <div className="border rounded">
        <div className="card-inner">
          <div className="card-title-group">
            <CardTitle>
              <h6 className="title">
                <h4 className="me-2">Pending Events</h4>
              </h6>
            </CardTitle>
          </div>
        </div>

        <label>
          <input
            type="search"
            className="ms-2 mb-1 form-control form-control-sm w-250px border-2"
            placeholder="Search..."
            onChange={(ev) => {
              setSearch(ev.target.value);
            }}
          />
        </label>

        {upcomingEventsLoading ? (
          <div>
            <ShimmerTable row={5} />
          </div>
        ) : (
          <div className="p-2">
            <ReactDataTable
              data={upcomingEventsList.data || []}
              columns={dataTableColumns}
              expandableRows
              pageNo={setPageNo}
              totalRecords={totalRecords}
              activePage={pageNo || 0}
              //loading={setLoading}
              //setSearch={setSearch}
              recordLimit={recordLimit}
              setRecordLimit={setRecordLimit}
              pagination
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default PendingEvent;
