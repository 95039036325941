import React, { useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
} from "reactstrap";
import { Icon } from "../../../../components/Component";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../../../assets/images/jsTree/Logo.png";
import { usePDF } from "react-to-pdf";
import { formatDate, formatTime } from "../../../../utils/Utils";
import { useState } from "react";
import "../../../../css/style.css";

const Agreement = ({
  clientName,
  clientLastName,
  email,
  organizationName,
  eventName,
  eventdate,
  setUpTime,
  startTime,
  endTime,
  packageName,
  packagePrice,
  packageDuration,
  addOnTotal,
  overtimeRateHourly,
  discount1,
  discount2,
  totalFee,
  Address1,
  address,
  eventcity,
  eventstate,
  eventcountry,
  eventzipcode,
  companyUserName,
  addonAllData,
  clientcity,
  clientcountry,
  clientstate,
  clientzipcode,
  contactNumber,

  InvoiceTravelFee,
}) => {
  const { toPDF, targetRef } = usePDF({ filename: "agreement.pdf" });
  const navigate = useNavigate();
  const { id } = useParams();
  const [addonData, setaddonData] = useState([]);

  useEffect(() => {
    if (addonAllData) {
      setaddonData(addonAllData);
    }
  }, [addonAllData]);

  const [isChecked, setIsChecked] = useState(false);

  function removeTrailingZero(input) {
    if (typeof input === "number") {
      return parseFloat(input); // For numbers
    } else if (typeof input === "string") {
      return input.replace(/\.0+$/, ""); // For strings
    }
    return input; // Return as-is if it's neither
  }

  // Examples
  console.log(removeTrailingZero(10.0)); // 10
  console.log(removeTrailingZero("10.0")); // "10"
  console.log(removeTrailingZero(10.5)); // 10.5
  console.log(removeTrailingZero("100")); // "100"

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  // Calculate total minutes between start and end times
  const calculateMinutesBetweenTimes = (stime, etime) => {
    if (!stime || !etime) return 0;

    const convertToMinutes = (time) => {
      const [timePart, modifier] = time.split(" ");
      let [hours, minutes] = timePart.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) hours += 12;
      if (modifier === "AM" && hours === 12) hours = 0;

      return hours * 60 + minutes;
    };

    const startTotalMinutes = convertToMinutes(stime);
    const endTotalMinutes = convertToMinutes(etime);
    const differenceInMinutes = Math.abs(endTotalMinutes - startTotalMinutes);

    console.log("Difference in Minutes:", differenceInMinutes);
    return differenceInMinutes;
  };

  const timedata = calculateMinutesBetweenTimes(startTime, endTime) / 60;
  console.log("ALL Addon DATA from Agree:", addonData);

  return (
    <>
      <div className="borderColor w-100 d-flex justify-content-center">
        <div className="w-75">
          <Row className="mt-4">
            <Col xs="12" lg="5">
              <div className="d-flex justify-content-center justify-content-lg-center">
                <img src={logo} alt="Logo" style={{ height: "150px" }} />
              </div>
              <div>
                <h3 className="text-center mt-5 mb-3">CONTRACT</h3>
              </div>
            </Col>
            <Col xs="12" lg="7" className="ps-4 pe-0">
              <Row className="w-100 fw-bold border rounded overflow-hidden">
                <Col
                  xs={12}
                  md={4}
                  className="d-flex align-items-center justify-content-center px-3"
                  style={{ backgroundColor: "#486E41" }}
                >
                  <Icon className="ni-lock px-1 fs-1 text-light" />
                  <p className="mb-0 fs-3 px-2 text-white text-center fst-italic">
                    Digitally Signed
                  </p>
                </Col>
                <Col
                  xs={12}
                  md={8}
                  className="text-start p-1"
                  style={{ backgroundColor: "#6DA264" }}
                >
                  <p className="fs-3 text-white ms-5 mb-1 fw-bold fst-italic">
                    {`${clientName} ${clientLastName}`}
                  </p>
                  <p className="mb-1  ms-5 text-white">
                    Contact: <strong>{contactNumber}</strong>
                  </p>
                  <p className="mb-0 ms-5 text-white">
                    Signed On: <strong>{eventdate}</strong>
                  </p>
                </Col>
              </Row>

              <Row className="w-100 fw-bold border rounded overflow-hidden mt-4">
                <Col
                  xs={12}
                  md={4}
                  className="d-flex align-items-center justify-content-center p-3"
                  style={{ backgroundColor: " #0E73A8" }}
                >
                  <Icon className="ni-lock px-1 fs-1 text-light" />
                  <p className="mb-0 fs-3 px-2 text-white text-center fst-italic">
                    Counter Signed
                  </p>
                </Col>

                <Col
                  xs={12}
                  md={8}
                  className="text-start p-1"
                  style={{ backgroundColor: " #6795CB" }}
                >
                  <p className="fs-3 text-white ms-5 mb-1 fw-bold fst-italic">
                    {`${clientName} ${clientLastName}`}
                  </p>
                  <p className="mb-1  ms-5 text-white">
                    Contact: <strong>{contactNumber}</strong>
                  </p>
                  <p className="mb-0 ms-5 text-white">
                    Signed On: <strong>{eventdate}</strong>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="borderColor mt-5">
            <Row>
              <Col sm="12">
                <h4 className="ms-5 mb-3 mt-3">Client Information</h4>
              </Col>
            </Row>
            <Row className="ms-3">
              <Col lg={6} className="mb-3">
                {clientName && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5 ">
                        <strong> Name: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6 ">{`${clientName} ${clientLastName}`}</p>
                    </Col>
                  </Row>
                )}

                {address && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Address: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6 ">
                        {[
                          Address1,
                          clientcity,
                          clientstate,
                          clientcountry,
                          clientzipcode,
                        ]
                          .filter(Boolean)
                          .join(" ")}
                      </p>
                      {/* <p className="mb-2 fs-6 ">
                        At-Post Itkare kolhapur Maharashtra,india 415403
                      </p> */}
                    </Col>
                  </Row>
                )}
              </Col>

              <Col lg={6} className="mb-3">
                <div></div>
                {email && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Email: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6 ">{email}</p>
                    </Col>
                  </Row>
                )}
                {contactNumber && (
                  <Row>
                    <Col className="d-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Contact: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6 ">{contactNumber}</p>
                    </Col>
                  </Row>
                )}
                {organizationName && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Organization: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6 ">{organizationName}</p>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>

          <div className="borderColor mt-0">
            <Row>
              <Col sm="12">
                <h4 className="ms-5 mb-3 mt-3">Event Information</h4>
              </Col>
            </Row>
            <Row className="ms-3">
              <Col lg={6} className="mb-3 ">
                {eventName && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5 ">
                        <strong> Event Name: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6">{eventName}</p>
                    </Col>
                  </Row>
                )}
                {eventdate && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Event Date: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6 ">{eventdate}</p>
                    </Col>
                  </Row>
                )}
                {packageName && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Package Name: </strong>
                      </p>
                    </Col>
                    <Col>
                      {packageName && (
                        <p className="mb-2 fs-6">{packageName}</p>
                      )}
                    </Col>
                  </Row>
                )}
                {packagePrice && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Package Price: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6">${packagePrice}</p>
                    </Col>
                  </Row>
                )}
                {address && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Address: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6 ">
                        {[eventcity, eventstate, eventcountry, eventzipcode]
                          .filter(Boolean)
                          .join(" ")}
                      </p>
                    </Col>
                  </Row>
                )}
              </Col>

              <Col lg={6} className="mb-3">
                {companyUserName && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> DJ Name: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6">{companyUserName}</p>
                    </Col>
                  </Row>
                )}
                {setUpTime && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> SetUp Time: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6">{setUpTime}</p>
                    </Col>
                  </Row>
                )}
                {startTime && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Start Time: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6">{startTime}</p>
                    </Col>
                  </Row>
                )}
                {endTime && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> End Time: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6">{endTime}</p>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>

          <div className="borderColor mt-0">
            <Row>
              <Col sm="12">
                <h4 className="ms-5 mb-3 mt-3">
                  Package Details / Add-ons Details
                </h4>
              </Col>
            </Row>
            <Row className="ms-0 ms-lg-5 me-0 me-lg-5">
              <Col lg={6} className="mb-4 border border-1 p-3">
                {packagePrice && (
                  <Row className="mt-3">
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Demo Package: </strong>
                      </p>
                    </Col>
                    <Col className="me-0">
                      <p className="mb-2 fs-6 ">
                        {packageDuration === "h"
                          ? `${packagePrice} X ${timedata} = ${
                              packagePrice * timedata
                            }`
                          : `$${packagePrice}`}
                      </p>
                    </Col>
                  </Row>
                )}

                {addOnTotal && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Addon Total: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6">${addOnTotal}</p>
                    </Col>
                  </Row>
                )}
                {overtimeRateHourly !== 0 && packageDuration === "d" && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Overtime Total: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6">${overtimeRateHourly}</p>
                    </Col>
                  </Row>
                )}
                {InvoiceTravelFee !== 0 && packageDuration === "d" && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Travel Fee: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6">${InvoiceTravelFee}</p>
                    </Col>
                  </Row>
                )}
                {discount1 !== 0 && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Discount-1: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6">${discount1}</p>
                    </Col>
                  </Row>
                )}

                {discount2 !== 0 && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Discount-2: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6">${discount2}</p>
                    </Col>
                  </Row>
                )}
                {totalFee !== 0 && totalFee && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Total: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6">${totalFee}</p>
                    </Col>
                  </Row>
                )}
              </Col>

              <Col lg={6} className="mb-3">
                {packageName && addonData.length > 0 && (
                  <Row>
                    <Col className="d-md-flex justify-content-md-end">
                      <p className="mb-2 fs-6 ms-0 ms-md-5">
                        <strong> Package Name: </strong>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-2 fs-6">{packageName}</p>
                    </Col>
                  </Row>
                )}

                <div className="me-5">
                  {addonData.length > 0 && (
                    <table className="table fs-6 me-2 table-responsive table-hover">
                      <thead>
                        <tr>
                          <th scope="col" className="text-center">
                            Addons Name
                          </th>
                          <th scope="col" className="text-center">
                            Quantity
                          </th>
                          <th scope="col" className="text-center">
                            Price
                          </th>
                        </tr>
                      </thead>
                      <tbody className="hover-primary">
                        {addonData.map((addon, index) => (
                          <tr key={index}>
                            <td className="text-center">{addon.addon_name}</td>
                            <td className="text-center">{addon.quantity}</td>
                            <td className="text-center">${addon.price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </Col>
            </Row>
          </div>

          <div className="borderColor my-4">
            <Row className="border bg-white mx-0">
              <Card className="card p-0">
                <CardHeader className="border-bottom fw-bold text-center fs-4 ">
                  Terms & Conditions
                </CardHeader>
                <CardBody className="card-body">
                  <div>
                    <table className="table border-none mb-0 ">
                      <tbody>
                        <tr>
                          <td className="bg-white">
                            <ul className="list-group list-group-flush list-group-numbered bg-white">
                              <li className="mb-4 bg-transparent">
                                <strong> 1. Payment Terms:</strong> A 50%
                                deposit is required upon booking to secure the
                                event date, with the remaining balance due one
                                week before the event. Payments should be made
                                via cash, check, or online transfer. Late
                                payments may result in a breach of contract and
                                forfeiture of event coverage without a refund.
                              </li>
                              <li className="mb-4 bg-transparent">
                                <strong>2. Cancellation Policy:</strong>{" "}
                                Cancellations made more than 30 days before the
                                event will receive a full refund. Cancellations
                                within 30 days will forfeit the deposit, with
                                considerations for rescheduling due to
                                unforeseen circumstances.
                              </li>
                              <li className="mb-4 bg-transparent">
                                <strong>3. Non-Refundable Retainer:</strong> A
                                non-refundable retainer of $200 is required upon
                                booking. If the event is canceled or
                                rescheduled, this retainer is not refundable. A
                                new retainer will be required if a new date is
                                booked.
                              </li>
                              <li className="mb-4 bg-transparent">
                                <strong>4. Client's Responsibilities:</strong>{" "}
                                Clients must provide completed event forms
                                (planner, requested songs, timeline) at least 15
                                days before the event. They are also responsible
                                for protecting the DJ’s equipment and ensuring
                                safe access for setup.
                              </li>
                              <li className="mb-4 bg-transparent">
                                <strong>5. DJ's Responsibilities:</strong> The
                                DJ will arrive 2-3 hours before the event to
                                ensure proper setup. If unable to perform due to
                                illness or uncontrollable events, a substitute
                                will be provided, or the payment will be
                                refunded.
                              </li>
                              <li className="mb-4 bg-transparent">
                                <strong>
                                  6. Performance Duration and Overtime Fees:
                                </strong>{" "}
                                The standard performance duration is four hours,
                                with an option for additional hours at an extra
                                charge. Overtime fees apply for exceeding the
                                agreed-upon duration.
                              </li>
                              <li className="mb-4 bg-transparent">
                                <strong>7. Venue Requirements:</strong> Venues
                                must provide adequate power outlets and ensure
                                compliance with local noise regulations. Advance
                                access arrangements for setup and breakdown are
                                required
                              </li>
                              <li className="mb-4 bg-transparent">
                                <strong>8. Liability and Insurance:</strong>{" "}
                                IPartyDJCRM holds liability insurance for
                                unforeseen accidents during the event. Clients
                                are responsible for obtaining any necessary
                                permits or licenses and acknowledge
                                non-liability for guest misconduct
                              </li>
                              <li className="mb-4 bg-transparent">
                                <strong>9. Weather Contingency:</strong> Clients
                                must arrange an alternate indoor location for
                                outdoor events. Events may be rescheduled or
                                canceled due to severe weather, and a backup
                                plan must be in place.
                              </li>
                              <li className="mb-4 bg-transparent">
                                <strong>
                                  10. Agreement Acceptance and Amendments:
                                </strong>{" "}
                                Booking the services implies acceptance of the
                                terms outlined. Any changes to the agreement
                                must be made in writing and signed by both
                                parties.
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="form-check ms-4">
                      <input
                        className="form-check-input fs-6"
                        type="checkbox"
                        id="agree"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <label class="form-check-label fs-6" for="agree">
                        I read and agree this terms and condition.
                      </label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </div>
        </div>
      </div>
      <Button
        className="px-3 my-2 btnprimary"
        color="primary"
        onClick={() => navigate("/my-events")}
      >
        <Icon className="ni-arrow-long-left"></Icon>
        <span> Back</span>
      </Button>
    </>
  );
};

export default Agreement;
