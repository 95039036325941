import { getCookie } from "../utils/Utils";

let token = process.env.REACT_APP_AUTH_TOKEN;

// Get API to fetch past events List =================================
export const getPastEventsList = async (values) => {
  const formData = new FormData();

  if (values.pageNo) {
    formData.append("page", values.pageNo);
  }
  if (values.recordLimit) {
    formData.append("limit", values.recordLimit);
  }
  if (values.search !== "") {
    formData.append("search_keyword", values.search);
  }

  formData.append("role", "client");
  formData.append("categorize_event", "history");

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", token),
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/events/get-event`,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting events list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Get API to fetch upcoming events List =================================
export const getUpcomingEventsList = async (values) => {
  const formData = new FormData();

  if (values.id !== "") {
    formData.append("id", values.id);
  }
  if (values.recordLimit) {
    formData.append("limit", values.recordLimit);
  }
  if (values.pageNo) {
    formData.append("page", values.pageNo);
  }
  if (values.booking_request_status) {
    formData.append("booking_request_status", values.booking_request_status);
  }
  formData.append("role", "client");

  if (values.category) {
    formData.append("categorize_event", values.category);
  }
  if (values.search !== "") {
    formData.append("search_keyword", values.search);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/events/get-event`,
    requestOptions
  );

  const res = await response.json();
  return res;
};

// Get API to fetch client payment details =================================
export const getPaymentDetails = async (values) => {
  const formData = new FormData();
  formData.append("event_id", values?.id);

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", token),
    },
    body: formData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/client/event-payment-details `,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while payment details");
    error.info = errorInfo;
    error.code = response.status;
    throw error;
  }
  const res = await response.json();
  return res;
};

// Get API to fetch Client details =================================
export const getClientsList = async () => {
  const formData = new FormData();
  formData.append("type", "client");

  // formData.append("first_name", first_name);
  // formData.append("last_name", last_name);
  // formData.append("organization_name", organization_name);
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", token),
    },
    body: formData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/client/get-client`,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting client details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();
  return res;
};

// Get API to fetch Notifications =================================
export const getNotifications = async (values) => {
  const formData = new FormData();

  if (values.limit) {
    formData.append("limit", values.limit);
  }
  if (values.offset) {
    formData.append("offset", values.offset);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", token),
    },
    body: formData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/public/get-notification`,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error(
      "An Error occured while getting notifications list"
    );
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  return response.json();
};

// export const getClientData = async () => {
//   const requestOptions = {
//     method: "POST",
//     headers: {
//       authToken: getCookie("authToken"),
//     },
//   };
//   try {
//     const response = await fetch(
//       `${process.env.REACT_APP_API_URL}api/v1/account/get-only-profile-img`,
//       requestOptions
//     );
//     if (!response.ok) {
//       const errorInfo = await response.json();
//       const error = new Error("An error occurred while fetching client data");
//       error.info = errorInfo;
//       error.code = response.status;
//       throw error;
//     }
//     const blob = await response.blob();
//     const imageUrl = URL.createObjectURL(blob);
//     // console.log(blob)
//     // console.log(imageUrl)
//     return imageUrl;
//   } catch (error) {
//     console.error("Error fetching client data:", error);
//   }
// };

export const getStates = async (values) => {
  const formData = new FormData();
  if (values.id !== "") {
    formData.append("country_id", values.id);
    // formData.append("country_code", values.id);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  // const response = await fetch(`${baseUrl}/public/states`, requestOptions);
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/public/states`,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting vender details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();

  return res;
};

export const getCountries = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      authToken: getCookie("authToken"),
    },
  };

  // const response = await fetch(`${baseUrl}/public/countries`, requestOptions);
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/public/countries`,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting Country list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();
  return res;
};

export const getCities = async (values) => {
  const formData = new FormData();
  if (values.id !== "") {
    formData.append("state_id", values.id);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  // const response = await fetch(`${baseUrl}/public/cities`, requestOptions);
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/public/cities`,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();
    const error = new Error("An Error occured while getting vender details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

export const getPaymentSettings = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      authToken: getCookie("authToken"),
    },
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/public/get-payment-setting`,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();
    const error = new Error("An Error occured while getting events list");
    error.info = errorInfo;
    error.code = response.status;
    throw error;
  }
  const res = await response.json();
  return res;
};
