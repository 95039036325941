import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
import { db } from "../../Firebase Files/firebaseConfig";
import { formatTimeForLastMessage } from "../../utils/Utils";

function ChatLastMessage({
  itemId,
  currentUserId,
  selectedId,
  setLastMessageTime,
}) {
  const [lastMessage, setLastMessage] = useState("Loading last message...");
  const [chatCount, setChatCount] = useState(0);

  useEffect(() => {
    let unsubscribeLastMessage;
    let unsubscribeUnreadMessages;

    async function setupListeners() {
      try {
        // Query to find the chatroom between the current user and the item ID
        const chatRoomsQuery = query(
          collection(db, "Chats"),
          where("participants", "array-contains", currentUserId)
        );

        const chatRoomsSnapshot = await getDocs(chatRoomsQuery);
        let chatRoomId = null;

        chatRoomsSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.participants.includes(itemId)) {
            chatRoomId = doc.id;
          }
        });

        if (!chatRoomId) {
          setLastMessage("No messages yet");
          setChatCount(0);
          return;
        }

        // Listener for the latest message in the chatroom
        const messagesQuery = query(
          collection(db, "Chats", chatRoomId, "messages"),
          orderBy("timestamp", "desc")
        );

        unsubscribeLastMessage = onSnapshot(messagesQuery, (snapshot) => {
          if (!snapshot.empty) {
            const validMessage = snapshot.docs.find((doc) => {
              const messageData = doc.data();

              const clearBy = messageData.clearedBy || [];
              return !clearBy.includes(currentUserId);
            });

            if (validMessage) {
              const latestMessage = validMessage.data();
              setLastMessage(
                latestMessage?.contactShare
                  ? "🪪ContactCard"
                  : latestMessage?.deleted
                  ? "Message has been deleted"
                  : latestMessage?.fileURLs?.length > 0
                  ? "🖇️Attachments"
                  : latestMessage?.text || "No messages yet"
              );
              setLastMessageTime(
                formatTimeForLastMessage(latestMessage?.timestamp)
              );
            } else {
              setLastMessage("No messages yet");
            }
          } else {
            setLastMessage("No messages yet");
          }
        });

        // Listener for unread messages for the current user
        const unreadMessagesQuery = query(
          collection(db, "Chats", chatRoomId, "messages")
        );

        unsubscribeUnreadMessages = onSnapshot(
          unreadMessagesQuery,
          (snapshot) => {
            // Filter out messages that have currentUserId in readBy array or senderId is currentUserId
            const unreadCount = snapshot.docs.filter((doc) => {
              const data = doc.data();
              const readBy = data.readBy || [];
              return (
                !readBy.includes(currentUserId) &&
                data.senderId !== currentUserId
              );
            }).length;
            setChatCount(unreadCount);
          }
        );
      } catch (error) {
        console.error("Error setting up listeners:", error);
      }
    }

    setupListeners();

    return () => {
      if (unsubscribeLastMessage) unsubscribeLastMessage();
      if (unsubscribeUnreadMessages) unsubscribeUnreadMessages();
    };
  }, [itemId, currentUserId]);

  return (
    <>
      <div className="d-flex justify-between">
        {itemId === selectedId ? (
          <p className="m-0">{lastMessage} </p>
        ) : (
          <>
            <p className={chatCount === 0 ? "" : "m-0 lead-text"}>
              {lastMessage}
            </p>
            {chatCount === 0 ? (
              ""
            ) : (
              <p className="m-0 badge bg-primary align-center rounded-circle mx-2">
                {chatCount}
              </p>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ChatLastMessage;
