import React, { useEffect, useState } from "react";
import { Icon } from "../../components/Component";
import SimpleBar from "simplebar-react";
import { Input } from "reactstrap";
import { ChatItem, ChatItemSearch } from "./ChatPartials";

import { ShimmerCategoryItem } from "react-shimmer-effects";
import NoData from "../../assets/images/NoData.png";
//import nodata2 from "../../assets/images/nodatablack.png";

import { getActiveEnvironmentVariable } from "../../utils/Utils";

export const ChatAsideBody = ({
  onInputChange,
  setSelectedId,
  selectedId,
  chatItemClick,
  filteredChatList,
  currentUser,
  messages,
  setMessages,
  filterText,
  loading,
  searchData,
  setLoading,
  setFilteredChatList,
  isRecentUsersFetched,
  selectedUser,
  setDeletedUserIds,
  isUpdating,
}) => {
  const activeVariable = getActiveEnvironmentVariable();
  console.log("filteredChatList:", filteredChatList);

  return (
    <>
      <div
        className="nk-chat-aside-search  top-0 bg-white  "
        style={{ height: "20px" }}
      >
        <div className="form-group mb-0">
          <div className="form-control-wrap position-relative">
            <div className="form-icon form-icon-left">
              <Icon name="search" />
            </div>
            <Input
              type="text"
              className="form-round"
              id="default-03"
              value={filterText || ""}
              placeholder="Search By Name"
              onChange={(e) => onInputChange(e)}
            />
            {filterText && (
              <div
                className="form-icon form-icon-right cursor-pointer"
                onClick={() => onInputChange({ target: { value: "" } })}
              >
                <Icon name="cross-circle" style={{ fontSize: "20px" }} />
              </div>
            )}
          </div>
        </div>
      </div>
      <SimpleBar className="nk-chat-aside-body h-100">
        <div
          className="nk-chat-list"
          style={{
            maxHeight: "calc(100vh - 60px)",
          }}
        >
          {/* <h6 className="title overline-title-alt">Messages</h6> */}
          <ul className="chat-list">
            {loading || !isRecentUsersFetched || isUpdating ? (
              [1, 2, 3, 4, 5].map((item, idx) => (
                <ShimmerCategoryItem
                  key={idx}
                  hasImage
                  imageType="circular"
                  imageWidth={60}
                  imageHeight={60}
                  text
                />
              ))
            ) : searchData !== null && searchData.length > 0 ? (
              searchData?.map((item, idx) => {
                return (
                  <ChatItemSearch
                    index={idx}
                    key={idx}
                    item={item}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                    chatItemClick={chatItemClick}
                    currentUser={currentUser}
                    messages={messages}
                    searchData={searchData}
                  ></ChatItemSearch>
                );
              })
            ) : searchData === null &&
              filteredChatList &&
              filteredChatList.length > 0 ? (
              filteredChatList?.map((item, idx) => {
                return (
                  <ChatItem
                    key={idx}
                    index={idx}
                    item={item}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                    chatItemClick={chatItemClick}
                    currentUser={currentUser}
                    selectedUser={selectedUser}
                    messages={messages}
                    searchData={searchData}
                    setLoading={setLoading}
                    setFilteredChatList={setFilteredChatList}
                    setDeletedUserIds={setDeletedUserIds}
                  ></ChatItem>
                );
              })
            ) : (
              <>
                <div className="flex-column justify-center align-center mt-5">
                  <div className="h-50 w-50">
                    <div>
                      {/* <img
                        src={activeVariable === "CRM" ? NoData : noResult}
                        alt="no chat image"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      /> */}
                      <img
                        src={NoData}
                        alt="no chat image"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div className="lead-text text-center mt-2">
                      {searchData !== null
                        ? "No Results Found"
                        : "No Chats Yet"}
                    </div>
                  </div>
                </div>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </>
  );
};
