import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Tooltip,
} from "reactstrap";
import { IoIosStar } from "react-icons/io";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router";
import { getCookie } from "../../../../utils/Utils";
import Swal from "sweetalert2";
import { loginData } from "../../../../components/table/TableData";
import Content from "../../../../layout/content/Content";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../../components/Component";

const Review = ({ companyid }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(Array(5).fill(false));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  const toggleTooltip = (index) => {
    setTooltipOpen((prevState) =>
      prevState.map((isOpen, i) => (i === index ? !isOpen : isOpen))
    );
  };

  const authToken = getCookie("authToken");
  console.log("authToken:", authToken);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!rating || !message || !title) {
      // setError("All Fileds are required.");
      toast.warning("All Fileds are required.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: { width: "20rem" },
      });
      return;
    }

    setLoading(true);
    setError("");

    const formData = new FormData();

    formData.append("event_id", id);
    formData.append("rating", rating);
    formData.append("title", title);
    formData.append("message", message);
    formData.append("company_id", companyid);
    console.log("Event ID from review:", id);
    console.log("Company ID from review:", companyid);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/public/add-reviews`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log("API Response:", data);

      if (data.status) {
        toast.success("Review Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { width: "20rem" },
        });
      } else {
        console.log("Meassage:", data);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.message,
          showCancelButton: true,
          confirmButtonText: "Pay Now",
          cancelButtonText: "Cancel",
          focusConfirm: false,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/my-events/payment-details/${id}`);

            console.log("Retry clicked");
          } else if (result.isDismissed) {
            console.log("Cancel clicked");
          }
        });
      }
      setRating(0);
      setMessage("");
      setTitle("");
    } catch (error) {
      console.error("Error submitting review:", error);
      // toast.error("Failed to submit review. Please try again.", {
      //   position: "top-right",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   style: { width: "20rem" },
      // });
      setError("Failed to submit review. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Add Your Review</BlockTitle>
              <BlockDes>
                <p className="fs-6">
                  "Your review helps us grow and improve – share your thoughts
                  today!".
                </p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <div>
          {error && <p className="text-danger">{error}</p>}
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="rating" className="d-block fs-6">
                Rating
              </Label>
              <div className="mb-3">
                {[...Array(5)].map((star, index) => {
                  const ratingValue = index + 1;
                  return (
                    <label key={index} className="me-1">
                      <Input
                        type="radio"
                        name="rating"
                        value={ratingValue}
                        onClick={() => setRating(ratingValue)}
                        style={{ display: "none" }}
                      />
                      <IoIosStar
                        className="star"
                        color={
                          ratingValue <= (hover || rating)
                            ? "#ffc107"
                            : "#e4e5e9"
                        }
                        size={30}
                        onMouseEnter={() => setHover(ratingValue)}
                        onMouseLeave={() => setHover(0)}
                        id={`star-${ratingValue}`}
                      />
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpen[index]}
                        target={`star-${ratingValue}`}
                        toggle={() => toggleTooltip(index)}
                      >
                        {ratingValue} Star{ratingValue > 1 ? "s" : ""}
                      </Tooltip>
                    </label>
                  );
                })}
              </div>
              <FormText color="muted">
                Please select a rating from 1 to 5 stars.
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label for="title" className="fs-5">
                {" "}
                Title
              </Label>
              <Input
                type="text"
                name="title"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="message" className="fs-5">
                Review
              </Label>
              <Input
                type="textarea"
                name="message"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="4"
              />
              <FormText color="muted">
                Share your experience or provide feedback.
              </FormText>
            </FormGroup>
            <div className="d-flex justify-content-end">
            <Button
              className="px-3 my-2 btnprimary "
              color="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
            </div>
          </Form>
        </div>

        <Button
          className="px-3 my-2 btnprimary"
          color="primary"
          onClick={() => navigate("/my-events")}
        >
          <Icon className="ni-arrow-long-left"></Icon>
          <span> Back</span>
        </Button>
      </Content>
    </React.Fragment>
  );
};

export default Review;
