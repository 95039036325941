import Router from "./route/Index";
import "./App.css";
import ThemeProvider from "./layout/provider/Theme";
import ToastLayout from "./components/toast/ToastLayout";
import { QueryClient, QueryClientProvider } from "react-query";
import DataProvider from "./context/DataProvider";
import { useEffect, useState } from "react";
import {
  ShimmerCategoryItem,
  ShimmerContentBlock,
  ShimmerPostDetails,
  ShimmerSimpleGallery,
  ShimmerText,
  ShimmerThumbnail,
} from "react-shimmer-effects";
const queryClient = new QueryClient();

const App = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Simulating a loading delay
    return () => clearTimeout(timer);
  }, []);
  if (loading) {
    return <div className="preloader"></div>;
  }

  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <DataProvider>
          <Router />
          <ToastLayout />
        </DataProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};
export default App;
