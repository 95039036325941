import React, { useState } from "react";
import { Button } from "reactstrap";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "../../../../components/Component";
import { getCookie } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { sendSMS } from "../../../../http/sms";
import { useQueryClient } from "react-query";
import Swal from "sweetalert2";
// import SMSForm from "../../../../http/sms";

let token = process.env.REACT_APP_AUTH_TOKEN;

const Paypal = ({ contact_number }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const eventvalue = getCookie("EventVal");
  const paymentType = getCookie("paymentType");
  const payer_id = getCookie("payerId");

  let paymentTypeText;
  if (paymentType === "1") {
    console.log(paymentType);
    paymentTypeText = "Retainer Payment";
  }
  if (paymentType === "2") {
    paymentTypeText = "Scheduled Payment 2 ";
  }
  if (paymentType === "3") {
    paymentTypeText = "Scheduled Payment 3 ";
  }
  const initialOptions = {
    "Client-id":
      "AQZbi5QAbfR6FkoAXQoR8vTqMqtVvXofmVwbWytgl43EvLpKEsyuWg42DaUXgh20brjZDICBz7lR46fV",
    currency: "USD",
    intent: "capture",
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "USD",
            value: eventvalue,
          },
        },
      ],
    });
  };
  var response_payment;
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      response_payment = details;
      const txn_id = details?.purchase_units?.[0].payments?.captures?.[0]?.id;

      handleSubmitSubscribePayment(txn_id, id, contact_number);
    });
  };
  const [data, setData] = useState(null);
  const fetchData = async () => {
    try {
      const formdata = new FormData();
      formdata.append("paypal_client_id ", "client_home_logo");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/account/payment-configuration`,
        {
          method: "POST",
          body: formdata,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData?.data?.site_settings?.[0]?.field_value);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmitSubscribePayment = async (txn_id, id, contact_number) => {
    let note;
    if (paymentType === 1) {
      note = "retainer payment";
    } else if (paymentType === 2) {
      note = "Scheduled 2 payment";
    } else if (paymentType === 3) {
      note = "scheduled 3 payment";
    }
    try {
      const formData = new FormData();
      formData.append("payment_txn_id", txn_id);
      formData.append("event_id", id);
      formData.append("payment_for", paymentType);
      formData.append("note", note);
      formData.append("mode", 3);
      formData.append("payer_id", payer_id);
      formData.append("payment_response", JSON.stringify(response_payment));
      formData.append("amount", eventvalue);
      formData.append("status", 3);
      formData.append("purpose", 2);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/client/event-payment`,
        {
          method: "POST",
          body: formData,
          headers: {
            authToken: getCookie("authToken", token),
          },
        }
      );
      const result = await response.json();
      if (response.ok) {
        // toast.success(`${paymentTypeText} Payment Done Successfully`);
        const number = "+91" + contact_number;
        const message = `Congratulations! ${paymentTypeText} of value $${eventvalue} done successfully`;
        Swal.fire({
          icon: "success",
          title: `${paymentTypeText} Done Successfully`,
          focusConfirm: false,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/my-events/event-details/${id}`);
          }
        });

        queryClient.invalidateQueries("get-notifications");
        // Cookies.remove("EventVal");
        // Cookies.remove("paymmnetType");
      } else {
        toast.error(result?.message || "Failed to submit payment.");
      }
    } catch (error) {
      toast.error("An error occurred during payment submission.");
      console.error("Error during payment submission:", error);
    }
  };

  return (
    <>
      <div className=" p-2">
        <p className="text-primary mb-4">
          <strong>{paymentTypeText}:</strong> $ {eventvalue}
        </p>
        <div className="d-flex align-items-center">
          <h5 className="mr-5 mb-0">Complete your Payment with Paypal:</h5>
          <div className="ms-4">
            <PayPalScriptProvider options={initialOptions}>
              <PayPalButtons
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
                onError={(err) => console.log(err)}
                fundingSource="paypal"
              />
            </PayPalScriptProvider>
          </div>
        </div>
      </div>

      <Button
        className="px-3 btn-primary"
        color="primary"
        onClick={() => navigate(`/my-events/event-details/${id}`)}
      >
        <Icon className="ni-arrow-long-left"></Icon>
        <span> Back</span>
      </Button>
    </>
  );
};

export default Paypal;
