import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import { ShimmerTable, ShimmerThumbnail } from "react-shimmer-effects";
import norecord from "../../../../images/No Result.png"
import { getCookie
  
 } from "../../../../utils/Utils";
const RequestedSong = () => {
  const { id } = useParams();

  const eventid = id;
  console.log("Event id from RequestedSong", eventid);

  const navigate = useNavigate();
  const [musicData, setMusicData] = useState([]);
  const [filteredMusicData, setFilteredMusicData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");

  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchData = async () => {
    if (!id) {
      console.warn("No valid event ID provided");
      return;
    }

    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("page", pageNo);
      formData.append("limit", recordLimit);
      formData.append("search", search);
      formData.append("event_id", id);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/client-dashboard/get-client-event-musics`,
        {
          method: "POST",
          headers: { authToken: getCookie("authToken") },
          body: formData,
        }
      );

      const responseData = await response.json();
      console.log("Response from get  event music", responseData);
      if (responseData?.data?.event_music_list) {
        // Filter the songs by the event ID
        const filteredByEvent = responseData.data.event_music_list.filter(
          (music) =>
            music.events.some((event) => event.event_id === parseInt(id))
        );

        setMusicData(filteredByEvent);
        setTotalRecords(filteredByEvent.length);
      } else {
        setMusicData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterMusic = () => {
    const filteredData = musicData.filter((music) =>
      music.music_title?.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredMusicData(filteredData);
  };

  useEffect(() => {
    if (id) fetchData();
  }, [pageNo, recordLimit, id]);

  useEffect(() => {
    filterMusic();
  }, [search, musicData]);

  return (
    <React.Fragment>
      <Content>
        <BlockHead size="lg" className="mb-2">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Requested Song</BlockTitle>
              <BlockDes>
                <p className="fs-6">
                "Your special song is about to transform this event into something extraordinary.!"
                </p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          {!id ? (
            <div className="text-center text-danger">
              No valid event ID provided. Please select a valid event.
            </div>
          ) : (
            <>
              <div className="my-3">
                {/* Search Input */}
                <input
                  type="text"
                  className="form-control w-25"
                  placeholder="Search by music or artist..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>

              <div className="table-responsive my-3">
                {isLoading ? (
                  <ShimmerTable row={5} col={4}  rounded />
                ) : (
                  <table className="table table-bordered mb-0">
                    {filteredMusicData && filteredMusicData.length > 0 ? (
                      <>
                        <thead>
                          <tr>
                            <th className="text-center fs-6" scope="col">
                              Sr.No
                            </th>
                            <th className="text-center fs-6" scope="col">
                              Music Name
                            </th>
                            <th className="text-center fs-6" scope="col">
                              Artist Name
                            </th>
                            <th className="text-center fs-6" scope="col">
                              Link
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredMusicData.map((music, index) => (
                            <tr key={music.eventid || index}>
                              <td className="text-center">{index + 1}</td>
                              <td className="text-center">
                                {music.music_title}
                              </td>
                              <td className="text-center">
                                {music.artist || "---"}
                              </td>
                              <td className="text-center">
                                <a
                                  href={music.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {music.link ? "View" : "---"}
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan="4" className="text-center">
                            <img
                              src={norecord}
                              alt="No Records Found"
                              style={{ maxWidth: "100px", margin: "20px 0" }}
                            />
                            <div className="py-2 fs-6">
                              There is no record found
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                )}
              </div>
            </>
          )}
        </Block>

        <Button
          className="px-3 my-2 btnprimary"
          color="primary"
          onClick={() => navigate("/my-events")}
        >
          <Icon className="ni-arrow-long-left"></Icon>
          <span>Back</span>
        </Button>
      </Content>
    </React.Fragment>
  );
};

export default RequestedSong;
