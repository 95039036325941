import React, { useEffect, useState } from "react";
import DataTablePagination from "./DataTablePagination";
import CustomizeReactDataTable from "./CustomizeReactDataTable";
import Content from "../../layout/content/Content";
import "../../css/style.css";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  ReactDataTable,
} from "../../components/Component";
import { Modal, Row } from "reactstrap";
import { ShimmerTable } from "react-shimmer-effects";
import { getCookie } from "../../utils/Utils";

const FavouriteSong = () => {
  const [musicData, setMusicData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  console.log("Check ===", isLoading);
  const [search, setSearch] = useState("");

  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedMusic, setSelectedMusic] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("page", pageNo + 1);
      formData.append("limit", recordLimit);
      formData.append("search", search);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/client-dashboard/get-client-event-musics`,
        {
          method: "POST",
          headers: { authToken: getCookie("authToken") },
          body: formData,
        }
      );

      const responseData = await response.json();

      if (responseData?.data) {
        setMusicData(responseData?.data?.event_music_list);
        setTotalRecords(responseData?.data?.pagination?.total || 0);
      } else {
        setMusicData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNo, recordLimit, search]);

  // const handleViewClick = (event, music) => {
  //   setSelectedMusic(music);
  //   setSelectedEvent(event);
  //   setShowModal(true);
  // };
  const handleViewClick = (event, music) => {
    // Filter out unavailable events before setting them in state
    const validEvents = event?.filter((e) => e?.event_name) || [];
    setSelectedEvent(validEvents);
    setSelectedMusic(music);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
    setSelectedMusic(null);
  };

  const dataTableColumns = [
    {
      name: "Sr.No",
      selector: (row, index) => pageNo * recordLimit + index + 1,
    },

    {
      name: "Music Name",
      selector: (row) => row?.music_title || "------",
    },
    {
      name: "Artist",
      selector: (row) => row?.music_artist || "------",
    },
    {
      name: "Music Link",
      selector: (row) => row?.music_provider_link || "------",
    },
    {
      name: "Events",
      cell: (row) => (
        <div
          onClick={() => handleViewClick(row.events)}
          className=" hover-effect"
        >
          <span className="text-primary view-text">View</span>
        </div>
        // <button
        //   className="btn btn-primary btn-sm"
        //   onClick={() => handleViewClick(row.events)} // Pass event details
        // >
        //   View
        // </button>
      ),
    },
  ];
  return (
    <>
      <Content>
        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <div className="d-flex flex-row align-center justify-between pt-3">
                <div>
                  <BlockTitle tag="h4">My Favourite Song</BlockTitle>
                </div>
              </div>
            </BlockHeadContent>
          </BlockHead>

          <PreviewCard>
            <Row>
              <div>
                <label>
                  <input
                    type="search"
                    className="ms-2 mb-3 form-control form-control-sm w-250px border-2"
                    placeholder="Search..."
                    onChange={(ev) => {
                      setSearch(ev.target.value);
                    }}
                  />
                </label>
              </div>
              <ReactDataTable
                data={musicData || []}
                columns={dataTableColumns}
                searchBy={"music_title"}
                pagination
                actions
                expandableRows
                loading={isLoading}
                pageNo={setPageNo}
                setSearch={setSearch}
                recordLimit={recordLimit}
                setRecordLimit={setRecordLimit}
                totalRecords={totalRecords}
                activePage={pageNo || 0}
              />
            </Row>
          </PreviewCard>
        </Block>
      </Content>

      {/* Modal for Event Details */}
      {showModal && (
        <Modal isOpen={showModal} toggle={closeModal} centered>
          <div className="modal-header d-flex justify-content-between">
            <h5 className="modal-title">Events Related To This Music</h5>

            <button type="button" className="close" onClick={closeModal}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {selectedEvent && selectedEvent.length > 0 ? (
              <div className="my-3 table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Event Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedEvent.map((event, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{event?.event_name || "---"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No event details available.</p>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default FavouriteSong;
