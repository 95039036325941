import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import Home from "../pages/home/Home";
import MyEvents from "../pages/myEvents/MyEvents";
import Contact from "../pages/contact/Contact";
import ChatConatiner from "../pages/Chat Files/ChatContainer";

// import Login from "../pages/Login/Login";
// import ForgotPassword from "../pages/Login/ForgotPassword";
// import EventDetails1 from "../pages/myEvents/buttons/EventDetails1/EventDetails";
// import EventSchedule from "../pages/myEvents/buttons/EventDetails/EventSchedule";
// import ServiceDetails from "../pages/myEvents/buttons/EventDetails/ServiceDetails";
// import PaymentDetails from "../pages/myEvents/buttons/EventDetails/PaymentDetails";
// import FeeDetails from "../pages/myEvents/buttons/EventDetails/FeeDetails";
// import Agreement from "../pages/myEvents/buttons/EventDetails/Agreement";
// import PlanEvent from "../pages/myEvents/components/PlanEvent";
// import Login from "../pages/Login/Login";
// import ForgotPassword from "../pages/Login/ForgotPassword";

import Payment from "../pages/myEvents/buttons/Payment/Payment";
import EventDetails from "../pages/myEvents/buttons/EventDetails/eventDetails";
import Profile from "../pages/profile/Profile";
import PaymentDetails from "../pages/myEvents/buttons/EventDetails/PaymentDetails";
import ChatNotification from "../layout/header/dropdown/notification/ChatNotification";
import InvoiceDetails from "../pages/myEvents/buttons/EventDetails/Invoice/Invoice";
import Invoice from "../pages/myEvents/buttons/EventDetails/Invoice/Invoice";
import TransactionDetails from "../pages/myEvents/buttons/EventDetails/TxnDetails";
import FavouriteSong from "../pages/Favourite-Song/FavouriteSong";

const Pages = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route
        path={`${process.env.PUBLIC_URL}`}
        element={
          <Layout
            app={{
              text: "Client Dashboard",
            }}
          />
        }
      >
        <Route index element={<Home />}></Route>
        <Route path="home" element={<Home />}></Route>
        <Route path="my-events" element={<MyEvents />}></Route>
        <Route path="contact-us" element={<Contact />}></Route>
        <Route path="profile" element={<Profile />}></Route>
        <Route path="chat" element={<ChatConatiner />}></Route>
        <Route path="chatnotification" element={<ChatNotification />}></Route>
        <Route path="favourite-song" element={<FavouriteSong/>}></Route>

        <Route
          path="my-events/event-details/:id"
          element={<EventDetails />}
        ></Route>

        <Route path="my-events/make-payment/:id" element={<Payment />}></Route>
        <Route
          path="my-events/payment-details/:id"
          element={<PaymentDetails />}
        ></Route>

        <Route
          path="my-events/payment-details/TransactionDetails"
          element={<TransactionDetails />}
        ></Route>
        {/*Invoice section comp... */}
        <Route
          path="/my-events/payment-details/Invoice/Invoice/:id"
          element={<Invoice />}
        />
        <Route
          path="/my-events/payment-details/Invoice/InvoiceDetails"
          element={<InvoiceDetails />}
        />
      </Route>

      {/* <Route path={`${process.env.PUBLIC_URL}`}>
        <Route index element={<Login />}></Route>
        <Route path="login" element={<Login />}></Route>
        <Route path="auth-reset" element={<ForgotPassword />}></Route>
      </Route> */}

      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </Routes>
  );
};
export default Pages;
