import { useNavigate } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { formatDate, getActiveEnvironmentVariable } from "../../../utils/Utils";
import client from "../../../client.json";
import "./Card.css";
import { useState } from "react";
const EventCard = ({
  eventType,
  eventName,
  eventDate,
  VenueName,
  VenueCity,
  VenueState,
  VenueCountry,
  id,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  function OpenEventDetails() {
    navigate(`/my-events/event-details/${id}`);
  }

  function OpenMakePayment() {
    navigate(`/my-events/event-details/${id}`);
  }
  const activeVariable = getActiveEnvironmentVariable();
  return (
    <>
      {/* <Col className="px-3 my-2" style={{ maxWidth: '500px' }}> */}
      <Col className="px-3 my-2">
        <Card className=" card card-bordered hover-card">
          <CardHeader className="border-bottom">
            <h5>Get Ready to Glow !</h5>
          </CardHeader>
          <CardBody className="card-inner">
            <CardTitle tag="h5">{eventType}</CardTitle>
            <CardText>
              <p className="mb-1">
                <strong className="text-dark ">
                  {client?.[activeVariable]?.sectin1?.map(
                    (item) => item?.title3
                  )}{" "}
                  Name:{" "}
                </strong>{" "}
                {eventName}
              </p>
              <p className="mb-1">
                <strong className="text-dark">
                  {client?.[activeVariable]?.sectin1?.map(
                    (item) => item?.title3
                  )}{" "}
                  Date:{" "}
                </strong>
                {formatDate(eventDate)}
              </p>
              {VenueName && (
                <p className="mb-0">
                  <strong className="text-dark">Venue: </strong>
                  {[VenueName, VenueCity, VenueState, VenueCountry]
                    .filter(
                      (value) =>
                        typeof value === "string" && value.trim().length > 0
                    )
                    .join(", ") || null}
                </p>
              )}
            </CardText>
            <div>
              <Row>
                <Col sm={12}>
                  <Button
                    color="primary btn-primary"
                    className=" mt-2 d-flex justify-content-center align-item-center"
                    block
                    onClick={OpenEventDetails}
                  >
                    <em class="icon ni ni-calender-date"></em>
                    <span>
                      {client?.[activeVariable]?.sectin1?.map(
                        (item) => item?.title3
                      )}{" "}
                      Details
                    </span>
                  </Button>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
      {/* <Col className="px-3 my-2">
  <Card className="card-bordered hover-card">
    <CardHeader className="border-bottom">
      <h5>Lights, Music, Action!</h5>
    </CardHeader>
    <CardBody className="card-inner">
      <CardTitle tag="h5">{eventType}</CardTitle>
      <CardText>
        <Row className="ms-3">
          <Col sm="12" className="mb-3">
            {eventName && (
              <Row>
                <Col lg="4" className="d-md-flex justify-content-md-start">
                  <p className="mb-2 fs-6 ms-0 ms-md-5 ">
                    <strong> Event Name: </strong>
                  </p>
                </Col>
                <Col lg="8">
                  <p className="mb-2 fs-6 ">{eventName}</p>
                </Col>
              </Row>
            )}

            {eventDate && (
              <Row>
                <Col lg="4" className="d-md-flex justify-content-md-start">
                  <p className="mb-2 fs-6 ms-0 ms-md-5">
                    <strong> Event Date: </strong>
                  </p>
                </Col>
                <Col lg="8">
                  <p className="mb-2 fs-6 ">{formatDate(eventDate)}</p>
                </Col>
              </Row>
            )}
            {VenueName && (
              <Row>
                <Col lg="4" className="d-md-flex justify-content-md-start">
                  <p className="mb-2 fs-6 ms-0 ms-md-5 ">
                    <strong> Event Address: </strong>
                  </p>
                </Col>
                <Col lg="8">
                  <p className="mb-2 fs-6 ">
                    {[VenueName, VenueCity, VenueState, VenueCountry]
                      .filter(Boolean)
                      .join(" ")}
                  </p>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </CardText>
      <div>
        <Row>
          <Col sm={12}>
            <Button
              color="primary btnprimary"
              className="mt-2 d-flex justify-content-center align-item-center"
              block
              onClick={OpenEventDetails}
            >
              <em className="icon ni ni-calender-date"></em>
              <span>Event Details</span>
            </Button>
          </Col>
        </Row>
      </div>
    </CardBody>
  </Card>
</Col> */}
    </>
  );
};

export default EventCard;
