import { Col, Row } from "reactstrap";
import Countdown from "../../components/countdown/Countdown";
import UpcommingEvents from "../myEvents/components/UpcommingEvents";
import WelcomeSection from "./components/WelcomeSection";
import welcome from "../../assets/images/Welcome.svg";
import { useEffect } from "react";
import Cookies from "js-cookie";
import Overview from "../myEvents/Overview";
import Events from "../myEvents/components/Events";

const Home = () => {
  return (
    <>
      <div className="px-2">
        <div className="">
          <Overview />
        </div>
        <div className="my-5">
          <Events />
        </div>
      </div>
    </>
  );
};
export default Home;
